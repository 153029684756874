<template>

</template>

<script>
export default {
  name: "TabBarItem"
}
</script>

<style scoped>


</style>